"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ng = window.angular;
const action_1 = require("@src/app/services/action");
class SalarycalculatorCtrl {
    constructor($scope, actionService) {
        this.$scope = $scope;
        this.actionService = actionService;
        this.period = 'month';
        this.hoursPerDay = 8;
        this.daysPerMonth = 20;
    }
    $onInit() {
    }
    submitForm($form) {
        if ($form.$valid && (this.amount != undefined) && (this.daysPerMonth != undefined) && (this.hoursPerDay != undefined)) {
            let result = {};
            if (this.period == 'hour') {
                result.hourly = this.hoursPerDay;
                result.daily = this.hoursPerDay * this.amount;
                result.monthly = result.daily * this.daysPerMonth;
            }
            else if (this.period == 'day') {
                result.daily = this.amount;
                result.monthly = result.daily * this.daysPerMonth;
            }
            else if (this.period == 'week') {
                result.weekly = this.amount;
                result.daily = this.amount / 5;
                result.monthly = result.daily * this.daysPerMonth;
            }
            else if (this.period == 'month') {
                result.monthly = this.amount;
                result.daily = result.monthly / this.daysPerMonth;
            }
            else if (this.period == 'semiMonth') {
                result.semiMonthly = this.amount;
                result.monthly = this.amount * 2;
                result.daily = result.monthly / this.daysPerMonth;
            }
            else if (this.period == 'quoter') {
                result.annual = this.amount * 3;
                result.quoter = this.amount;
                result.monthly = this.amount / 3;
                result.daily = result.monthly / this.daysPerMonth;
            }
            else if (this.period == 'semiAnnual') {
                result.annual = this.amount * 2;
                result.monthly = result.annual / 12;
                result.weekly = result.annual / 52;
                result.daily = result.monthly / this.daysPerMonth;
            }
            else if (this.period == 'year') {
                result.annual = this.amount;
                result.weekly = this.amount / 52;
                result.monthly = result.weekly * 4;
                result.daily = result.monthly / this.daysPerMonth;
            }
            if (Object.keys(result).length > 0) {
                result.hourly = result.hourly ? result.hourly : (result.daily / this.hoursPerDay);
                result.weekly = result.weekly ? result.weekly : (result.monthly / 4);
                result.semiMonthly = result.semiMonthly ? result.semiMonthly : (result.monthly / 2);
                result.quoter = result.quoter ? result.quoter : (result.monthly * 3);
                result.annual = result.annual ? result.annual : (result.monthly * 12);
                result.semiAnnual = result.semiAnnual ? result.semiAnnual : (result.annual / 2);
                result.minute = result.hourly / 60;
                this.result = result;
            }
        }
    }
    clear($form) {
        $form.$setPristine();
        $form.$setUntouched();
        this.result = undefined;
        this.daysPerMonth = undefined;
        this.hoursPerDay = undefined;
        this.amount = undefined;
    }
    copy(id, value) {
        if (this.result) {
            this.actionService.copyFactory(document.getElementById(id), value);
        }
    }
}
SalarycalculatorCtrl.$inject = ['$scope', 'ActionService',];
const appModule = ng.module('app');
appModule.service('ActionService', action_1.ActionService);
appModule.component('gameSalarycalculator', {
    transclude: true,
    template: require("./game.ng.html"),
    controller: SalarycalculatorCtrl,
    controllerAs: '$ctrl',
    bindings: {
        config: "<"
    }
});
appModule.config(['WsServiceProvider', (WsServiceProvider) => {
        WsServiceProvider.setPrefix('salarycalculator/');
    }]);
